import { render, staticRenderFns } from "./ModalGroups.vue?vue&type=template&id=78f638cd&scoped=true&"
import script from "./ModalGroups.vue?vue&type=script&lang=js&"
export * from "./ModalGroups.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f638cd",
  null
  
)

export default component.exports